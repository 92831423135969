<template>
  <div class="container-fluid bg-dark text-light footer pt-5 wow fadeIn" data-wow-delay="0.1s" style="margin-top: 6rem;">
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-lg-4 col-md-6">
          <h4 class="text-light mb-4">CONTACT</h4>
          <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>Unit C1, 7/F Bonjour Tower,<br>No.36-42 Wang Wo Tsai Street,<br>Tsuen Wan, N.T, HongKong</p>
          <p class="mb-2"><i class="fa fa-comment me-3"></i><a href="http://pf.kakao.com/_ezCxhC/chat" target="_blank">withpd</a></p>
          <p class="mb-2"><i class="fa fa-phone-alt me-3"></i><a href="tel:010-8235-0642">+852 5316 1715</a></p>
          <p class="mb-2"><i class="fa fa-phone-alt me-3"></i><a href="tel:010-8235-0642">010 8235 0642</a></p>
          <p class="mb-2"><i class="fa fa-envelope me-3"></i>geek9geek9@gmail.com</p>
          <div class="d-flex pt-2">
            <a class="btn btn-outline-light social" ><i class="fas fa-handshake" onclick="download()"></i></a>
            <a class="btn btn-outline-light social" href="https://blog.naver.com/sosoceo" target="_blank"><i class="fas fa-bullhorn"></i></a>
            <a class="btn btn-outline-light social" href="https://www.instagram.com/geek9geek9/reels/" target="_blank"><i class="fab fa-instagram"></i></a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <h4 class="text-light mb-4">COMPANY</h4>
          <p class="mb-2"><i class="fas fa-hotel me-3"></i>하나됨</p>
          <p class="mb-2"><i class="fas fa-id-card me-3"></i>배성호</p>
          <p class="mb-2"><i class="fas fa-mail-bulk me-3"></i>628-27-00300</p>
          <p class="mb-2"><i class="fas fa-satellite-dish me-3"></i>2018-인천연수구-0625</p>
          <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>인천 예술로 226번길 17, A-608</p>
        </div>
        <div class="col-lg-4 col-md-6">
          <h4 class="text-light mb-4">SERVICE</h4>
          <a class="btn service-link">(국내외) 보관 / 포장 / 배송</a>
          <a class="btn service-link">수출입 통관</a>
          <a class="btn service-link">물류대행</a>
          <a class="btn service-link">해외이사</a>
          <a class="btn service-link">식품 보관 / 배송</a>
          <a class="btn service-link">해외재고관리</a>
          <a class="btn service-link" href="https://geek9.kr" target="_blank">B2C</a>
          <a class="btn service-link" href="https://cafe.naver.com/geek9" target="_blank">CAFE</a>
        </div>
      </div>
    </div>
    <div class="container">
      <div style="padding:25px;">
        <div class="row">
          <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
            &copy; <a class="border-bottom" href="/biz">GEEK9</a>, All Right Reserved.
          </div>
          <div class="col-md-6 text-center text-md-end">
            Designed By <a style="color:white;" target="_blank" href="https://htmlcodex.com">HTML Codex</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
}
</script>

<style scoped>
  .social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    border: 1px solid #FFFFFF;
    border-radius: 35px;
    transition: .3s;
  }
  .social:hover {
    color:#ffc107;
  }
  .service-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
  }
  .service-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
  }
  .service-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
  }
  @import '../../assets/css/bootstrap.min.css';

</style>